import React from "react";
import "../../scss/dist/NavBar.css";
import NeoLogo from "../../assets/neo-logo-white.svg";
function NavBar() {
	return (
		<div className="NavBar">
			<a href="https://neo.ae/">
				<img className="Logo" src={NeoLogo} alt="Neo Mena's Logo" />
			</a>

			<h3 className="title-nav">Task Assesment - Mohammad Chreif</h3>
		</div>
	);
}

export default NavBar;
