import React, { useEffect } from "react";
import "../../scss/dist/Home.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function Home(props) {
	const history = useHistory();
	// Getting the Full name value from the other page, with default value of ""
	const fullName = props.location.state?.fullname ?? "";
	const [FullName, setFullName] = useState("Lorem Ipsum Lorem");

	useEffect(() => {
		// check if not empty and sets fullName to the value we got from the other page
		if (fullName.trim() !== "") {
			setFullName(fullName);
		}
	}, []);
	// redirect function to redirect us to the Edit page
	const redirect = () => {
		let path = "/Edit";
		history.push({
			pathname: path,
			state: { fullname: FullName },
		});
	};
	return (
		<div className="Home">
			<div className="inputContainer">
				<input type="text" name="Full Name" id="" value={FullName} readOnly />
				<button className="editbtn" name="Edit" onClick={redirect}>
					{" "}
					Edit{" "}
				</button>
			</div>

			<a
				className="repoText"
				href="https://github.com/mo-chr/neo-mena-task"
				target="_blank"
			>
				Click me to access the Github Repo
			</a>
		</div>
	);
}

export default Home;
