import React, { useEffect, useState } from "react";
import "../../scss/dist/Edit.css";
import Dropdown from "../../components/DropDown/Dropdown";
import { useHistory } from "react-router-dom";
function Edit(props) {
	const history = useHistory();
	// fetching the full name var from Home Page
	const fullName = props.location.state?.fullname ?? "";
	// splitting the full name from the Home page making it an array
	const arrayOfNames = fullName.split(" ");

	// Creating the array of names with values and labels based on the full name from the Home Page
	const options = arrayOfNames.map((word, index) => ({
		value: word,
		label: `NAME_${index + 1}(${word})`,
	}));
	// State vars
	const [FirstName, setFirstName] = useState([]);
	const [MiddleName, setMiddleName] = useState([]);
	const [LastName, setLastName] = useState([]);
	const [FilteredOptions, setFilteredOptions] = useState([]);
	const [Text, setText] = useState(" ");
	const [SelectedOptions, setSelectedOptions] = useState([]);
	// Setting the filtered options to be the exclusion of the options that are already selected
	useEffect(() => {
		const filteredOptions = options.filter(
			(option) => !SelectedOptions.includes(option.label)
		);

		setFilteredOptions(filteredOptions);
	}, [SelectedOptions]);
	//combining the first, middle and last name labels(using labels to identify them because some words may repeat so it's technically an ID)
	useEffect(() => {
		const combinedOptions = [
			...FirstName.map((element) => element.label),
			...MiddleName.map((element) => element.label),
			...LastName.map((element) => element.label),
		];
		// storing them in an array which indicates all the selected Options
		setSelectedOptions(combinedOptions);
	}, [FirstName, MiddleName, LastName]);

	// function on select
	const SelectValue = (value, variableName) => {
		// checks if it's First Middle or last name and sets it accordingly
		if (variableName == "First") {
			setFirstName(value);
		} else if (variableName == "Middle") {
			setMiddleName(value);
		} else {
			setLastName(value);
		}
	};
	// Click Handler
	const handleClick = () => {
		// Joining the values of the names all together in one string
		const fullName =
			// looping through the arrays of First/Mid and last since there's multi select and joining them all together
			FirstName.map((value) => value.value).join(" ") +
			" " +
			MiddleName.map((value) => value.value).join(" ") +
			" " +
			LastName.map((value) => value.value).join(" ");
		// secondary layer of validation
		if (FirstName.length > 0 && LastName.length > 0 && MiddleName.length > 0) {
			setText(fullName);
		}
		// redirecting to Home Page
		let path = "/";
		history.push({
			pathname: path,
			state: { fullname: fullName },
		});
	};

	return (
		<div className="Edit">
			<div className="drop-container">
				<div className="dropdown">
					<label htmlFor="firstName"> First Name</label>
					<Dropdown
						id="firstName"
						isSearchable
						isMulti
						placeHolder="Select..."
						options={FilteredOptions}
						onChange={(value) => SelectValue(value, "First")}
					/>
				</div>

				<div className="dropdown">
					<label htmlFor="middleName"> Middle Name</label>
					<Dropdown
						id="middleName"
						isSearchable
						isMulti
						placeHolder="Select..."
						options={FilteredOptions}
						onChange={(value) => SelectValue(value, "Middle")}
					/>
				</div>

				<div className="dropdown">
					<label htmlFor="lastName"> LastName</label>
					<Dropdown
						id="lastName"
						isSearchable
						isMulti
						placeHolder="Select..."
						options={FilteredOptions}
						onChange={(value) => SelectValue(value, "Last")}
					/>
				</div>
				<button
					className="savebtn"
					name="Save"
					// checks the length of the array of each component to determine whether it's filled or not
					disabled={
						FirstName.length <= 0 ||
						MiddleName.length <= 0 ||
						LastName.length <= 0
					}
					onClick={handleClick}
				>
					Save
				</button>
			</div>
			<div className="infoContainer">
				<h4 className="debugtab">Debugging tab</h4>
				<span>
					First Name :{" "}
					<a className="answer">{FirstName.map((element) => element.value)}</a>
				</span>
				<span>
					Middle name :{" "}
					<a className="answer">{MiddleName.map((element) => element.value)}</a>
				</span>
				<span>
					Last Name :{" "}
					<a className="answer">{LastName.map((element) => element.value)}</a>
				</span>
				<br />
				<span>
					Available Options :{" "}
					<a className="answer">
						{FilteredOptions.map((element) => element.value + " ")}
					</a>
				</span>
				<br />
				<span>
					{" "}
					Full Name : <a className="answer">{fullName}</a>
				</span>
				<br />
				<span>
					Result :<a className="answer">{Text}</a>{" "}
				</span>
			</div>
		</div>
	);
}

export default Edit;
