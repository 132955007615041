import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Edit from "./pages/Edit/Edit";
import "./scss/dist/App.css";
import NavBar from "./components/NavBar/NavBar";

function App() {
	return (
		<React.StrictMode>
			<Router>
				<NavBar />
				<Route exact path="/" component={Home} />
				<Route exact path="/Edit" component={Edit} />
			</Router>
		</React.StrictMode>
	);
}

ReactDOM.render(<App />, document.getElementById("root"));

export default App;
